import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import cx from "classnames";
import chevronRightDarkIcon from "~/images/icons/chevron-right-dark.svg";
import lendingIcon from "~/images/products/lending.png";
import liqudityMiningIcon from "~/images/products/liqudity-mining.png";
import bespokeStrategiesIcon from "~/images/products/bespoke-strategies.png";
import structuredProductsIcon from "~/images/products/structured-products.png";
import ProductCard from "./ProductCard";
import { Link } from "gatsby";
import Button from "~/ui/Button";

const Arrow = ({ className, direction, onClick }) => (
  <button
    className={cx(
      className,
      "w-7 h-7 rounded-full bg-gray-75 bg-opacity-50 flex items-center justify-center"
    )}
    onClick={onClick}
    type="button"
  >
    <img
      src={chevronRightDarkIcon}
      alt="chevron"
      className={cx(
        "absolute z-10 top-1/2 transform -translate-y-1/2 w-3",
        direction === "left" ? "left-2 rotate-180" : "right-2"
      )}
    />
  </button>
);

const ProductOfferings = () => {
  const { t } = useTranslation();
  const ref = useRef<any>();
  const next = () => {
    if (ref.current) ref.current.slickNext();
  };
  const previous = () => {
    if (ref.current) ref.current.slickPrev();
  };

  const [currentSlide, setCurrentSlide] = useState(0);

  const items = [
    {
      key: "lending",
      alt: "Lending",
      title: "Lending",
      content: t(
        "Lend assets at low risk and high capital efficiency to leveraged yield farmers in exchange for lending fees and protocol rewards."
      ),
      icon: lendingIcon,
    },
    {
      key: "market neutral strategies",
      alt: "Market Neutral Strategies",
      title: "Market Neutral Strategies",
      content: t(
        "Provide liquidity to market-neutral stablecoin-stablecoin trading pairs (USDT, BUSD, TUSD) on decentralized exchanges, acting as an automated market-maker to receive trading fees and protocol rewards from said DEXs."
      ),
      icon: liqudityMiningIcon,
    },
    {
      key: "structured products",
      alt: "Structured Products",
      title: "Structured Products",
      content: t(
        "We provide high yield products offering elements of fixed income, principal protection, delta-neutral exposure, and risk tranching."
      ),
      icon: structuredProductsIcon,
    },
    {
      key: "bespoke strategies",
      alt: "Bespoke Strategies",
      title: "Bespoke Strategies",
      content: t(
        "We develop bespoke strategies and products for our institutional partners according to their financial and jurisdictional requirements."
      ),
      icon: bespokeStrategiesIcon,
    },
  ];

  return (
    <section className="mt-16 lg:-mt-16 lg:relative">
      <div className="w-48 mx-auto text-xl font-medium text-center mb-16 lg:container lg:w-full lg:text-4xl lg:font-semibold">
        {t("Our Current Product Offerings")}
      </div>
      <div className="relative lg:hidden">
        <Slider
          ref={ref}
          arrows={false}
          variableWidth
          centerMode
          adaptiveHeight
          beforeChange={(_, nextSlide) => setCurrentSlide(nextSlide)}
        >
          {items.map(({ key, ...props }, i) => (
            <div
              className="container mx-auto px-1"
              style={{ width: 230 }}
              key={key}
            >
              <ProductCard
                className={currentSlide !== i && "scale-90"}
                {...props}
              />
            </div>
          ))}
        </Slider>
        <div className="absolute inset-0 pointer-events-none lg:hidden">
          <div className="w-72 mx-auto relative h-full">
            <Arrow
              className="absolute top-1/2 transform -translate-y-1/2 left-0 w-5 pointer-events-auto"
              direction="left"
              onClick={previous}
            />
            <Arrow
              className="absolute top-1/2 transform -translate-y-1/2  right-0 w-5 pointer-events-auto"
              direction="right"
              onClick={next}
            />
          </div>
        </div>
      </div>
      <div className="hidden lg:container lg:mx-auto lg:grid lg:grid-cols-4 lg:gap-x-6">
        {items.map(({ key, ...props }) => (
          <ProductCard
            appearance="desktop"
            key={key}
            className="col-span-1"
            {...props}
          />
        ))}
      </div>
      <div className="text-center mt-28 lg:mt-12">
        <Link to="https://forms.gle/HMJSZhu3LU2dymgs8" target="_blank" rel="nofollow noopener noreferrer">
          <span className="text-primary text-sm font-bold lg:text-xl lg:font-medium underline">
            {t("Contact us to find out more")}
          </span>
        </Link>
      </div>
    </section>
  );
};
export default ProductOfferings;
