import React from "react";
import cx from "classnames";
import { useI18next } from "gatsby-plugin-react-i18next";
import { PoolResponse } from "./hooks";
import bnb from "~/images/coins/bnb.svg";
import alpacaLogo from "~/images/alpaca-logo.svg";
import { formatPercentage, humanFormatCurrency } from "~/format/utils";
import millify from "millify";

interface Props extends PoolResponse {
  className?: string;
}

const icons = {
  BNB: bnb,
};

const PoolCard: React.FC<Props> = ({
  className,
  symbol,
  farmURL,
  totalSupply,
  totalBorrowed,
  utilization,
  totalAPY,
  apr,
  grassHouseTotalApy,
}) => {
  const { language, t } = useI18next();

  const asset = (
    <div className="flex items-center">
      <img
        className="w-7 h-7 lg:w-12 lg:h-12"
        src={`https://alpaca-app-asset.s3-ap-southeast-1.amazonaws.com/icons/coins/${symbol.toLowerCase()}.svg`}
        alt={symbol}
      />
      <span className="inline-block ml-2 text-dark-450 lg:text-xl lg:ml-4">
        {symbol}
      </span>
    </div>
  );

  const alpacaLock = 
    symbol.toLowerCase() === 'alpaca' ? (
      <a href="https://gov.alpacafinance.org/" target="_self">
        <button
          type="button"
          className="lg:w-full mb-2 px-2 py-1 bg-gradient-to-br from-primary via-green-80 to-green-50 border-2 border-green-80 rounded-md"
        >
          <span className="font-bold text-xs">{t(`Lock for ${formatPercentage(grassHouseTotalApy, language)} APY`)}</span>
        </button>
      </a>
    ) : (
      <></>
    )
   

  const farmButton = (
    <a href={farmURL} target="_self">
      <button
        type="button"
        className="lg:w-full px-5 py-1 from-primary bg-clip-text bg-gradient-to-br to-green-50 text-transparent  border-2 border-green-80 rounded-md lg:px-8"
      >
        <span className="font-bold">{t("Lend")}</span>
      </button>
    </a>
  );

  const apys = (
    <>
      <div className="ml-4 bg-clip-text bg-gradient-to-br from-primary to-green-50 text-transparent font-medium text-base lg:text-dark-450 lg:text-xl lg:font-normal lg:mb-1">
        {formatPercentage(totalAPY, language)}
      </div>
      {/* <div className="ml-2 border border-light rounded-md text-dark-450 text-xs px-1 py-px flex items-center lg:inline-flex">
        <img className="w-4 mr-1" src={alpacaLogo} alt="Alpaca Finance" />
        {formatPercentage(apr, language)} {t("APR")}
      </div> */}
    </>
  );

  return (
    <div className={cx("bg-white rounded-lg", className)}>
      <div className="flex justify-between p-3 border-b border-light lg:hidden">
        {asset}
        {alpacaLock}
        {farmButton}
      </div>
      <div className="p-3 lg:p-0">
        <div className="flex lg:grid lg:grid-cols-12 lg:gap-x-2 lg:text-right lg:py-4 lg:items-center">
          <div className="hidden lg:col-span-2 lg:pl-12 lg:flex lg:items-center">
            {asset}
          </div>
          <div className="w-2/5 lg:col-span-2 lg:w-auto">
            <div className="text-gray-50 font-medium text-xs mb-1 lg:hidden">
              {t("Total Supply")}
            </div>
            <div className="text-dark-450 font-medium text-base lg:text-xl lg:font-normal">
              {millify(totalSupply, { precision: 2 })}
            </div>
          </div>
          <div className="w-2/5 lg:col-span-2 lg:w-auto">
            <div className="text-gray-50 font-medium text-xs mb-1 lg:hidden">
              {t("Total Borrowed")}
            </div>
            <div className="text-dark-450 font-medium text-base lg:text-xl lg:font-normal">
              {millify(totalBorrowed, { precision: 2 })}
            </div>
          </div>
          <div className="w-1/5 lg:col-span-2 lg:w-auto lg:pr-3">
            <div className="text-gray-50 font-medium text-xs mb-1 lg:hidden">
              {t("Utilization")}
            </div>
            <div className="text-dark-450 font-medium text-base lg:text-xl lg:font-normal">
              {formatPercentage(utilization, language)}
            </div>
          </div>
          <div className="hidden lg:block lg:col-span-2 lg:pr-9">{apys}</div>
          <div className="hidden lg:block lg:col-span-2 lg:pr-9">
            {alpacaLock}
            {farmButton}
          </div>
        </div>
        <div className="flex items-center mt-8 lg:hidden">
          <div className="text-gray-50 font-medium text-xs">
            {t("Total APY")}
          </div>
          {apys}
        </div>
      </div>
    </div>
  );
};

export default PoolCard;
