import React from 'react';
import cx from 'classnames';

interface Props {
  className?: string;
  icon: string;
  alt: string;
  title: string;
  content: string;
  appearance?: 'mobile' | 'desktop';
}

const ProductCard: React.FC<Props> = ({
  className,
  icon,
  alt,
  title,
  content,
  appearance = 'mobile',
}) => (
  <div
    className={cx(
      'border border-primary bg-dark-500 rounded-xl px-4 py-8 transform-gpu transition-transform duration-300',
      'lg:pt-10 lg:pb-2 lg:flex lg:flex-col',
      className,
    )}
    style={{
      height: appearance === 'mobile' ? 400 : 'auto',
      minHeight: appearance === 'desktop' ? 516 : 0,
    }}
  >
    <img
      className="w-20 mx-auto lg:w-auto lg:h-40 lg:flex-none"
      src={icon}
      alt={alt}
    />
    <div className="text-center my-8 font-medium text-base lg:h-16 lg:leading-relaxed lg:flex-none lg:flex lg:items-center lg:justify-center lg:text-2xl">
      {title}
    </div>
    <p className="text-xs leading-relaxed lg:flex-1 lg:text-sm lg:leading-relaxed">
      {content}
    </p>
  </div>
);

export default ProductCard;
