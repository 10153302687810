import React, { useState, useRef } from "react";
import cx from "classnames";
import { useTranslation } from "react-i18next";

const items = [
  {
    logo: "https://alpaca-app-asset.s3.ap-southeast-1.amazonaws.com/partners/gsr.svg",
    label: "GSR",
    alt: "gsr",
    href: "https://www.gsr.io/",
    logoBackground: "",
  },
  {
    logo: "https://alpaca-app-asset.s3.ap-southeast-1.amazonaws.com/partners/trustology.svg",
    label: "Trustology",
    alt: "trustology",
    href: "https://www.trustology.io/",
    logoBackground: "",
  },
  {
    logo: "https://alpaca-app-asset.s3.ap-southeast-1.amazonaws.com/partners/x-margin.svg",
    label: "X-Margin",
    alt: "x-margin",
    href: "https://xmargin.io/",
    logoBackground: "",
  },
  {
    logo: "https://alpaca-app-asset.s3.ap-southeast-1.amazonaws.com/partners/gnosis.svg",
    label: "Gnosis",
    alt: "gnosis",
    href: "https://gnosis.io/",
    logoBackground: "",
  },
  {
    logo: "https://alpaca-app-asset.s3.ap-southeast-1.amazonaws.com/partners/system9.svg",
    label: "System 9",
    alt: "system-9",
    href: "https://system9.io/",
    logoBackground: "",
  },
  {
    logo: "https://alpaca-app-asset.s3.ap-southeast-1.amazonaws.com/partners/tusd-logo.svg",
    label: "TrustToken",
    alt: "trusttoken",
    href: "https://www.trusttoken.com/",
    logoBackground: "",
  },
  {
    logo: "https://alpaca-app-asset.s3.ap-southeast-1.amazonaws.com/partners/NEXUS.svg",
    label: "Nexus Mutual",
    alt: "nexus-mutual",
    href: "https://nexusmutual.io/",
    logoBackground: "bg-white",
  },
  {
    logo: "https://alpaca-app-asset.s3.ap-southeast-1.amazonaws.com/partners/chainlink.svg",
    label: "Chainlink",
    alt: "chainlink",
    href: "https://chain.link/",
    logoBackground: "bg-white",
  },
  {
    logo: "https://alpaca-app-asset.s3.ap-southeast-1.amazonaws.com/partners/binance.svg",
    label: "Binance",
    alt: "binance",
    href: "https://www.binance.com/en",
    logoBackground: "",
  },
];

const Partners: React.FC = () => {
  const { t } = useTranslation();
  const [currentSlide, setCurrentSlide] = useState(0);
  const ref = useRef<any>();
  const next = () => {
    if (ref.current) ref.current.slickNext();
  };
  const previous = () => {
    if (ref.current) ref.current.slickPrev();
  };

  return (
    <section className="lg:relative">
      <div className="relative container mx-auto mt-8 lg:mt-16">
        <div className="relative px-4 lg:px-0 inline-block text-2xl font-medium lg:text-section-header-desktop mb-8 lg:mb-16">
          <div className="absolute header-background z-0 top-0 left-0 h-full w-full"></div>
          <span className="relative">{t("Our Partners")}</span>
        </div>
      </div>
      <div>
        <div className="grid grid-cols-2 container mx-auto gap-4 lg:grid-cols-4 lg:gap-2">
          {items.map(({ logo, label, alt, href, logoBackground }) => (
            <a
              className="col-span-1 p-4 border border-gray-100 bg-dark-500 rounded-xl lg:flex lg:items-center lg:py-3 lg:px-4"
              href={href}
              key={alt}
              target="_blank"
              rel="nofollow noopener noreferrer"
              style={{ zIndex: 20 }}
            >
              <img
                className={`w-11 lg:w-16 rounded-full ${logoBackground}`}
                src={logo}
                alt={alt}
              />
              <div className="mt-9 text-m lg:mt-0 lg:ml-4 lg:text-2xl">
                {label}
              </div>
            </a>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Partners;
