import { useEffect, useState } from "react";

export type PoolType = "lending" | "neutral";

export type PoolResponse = {
  id: number;
  symbol: string;
  farmURL: string;
  totalSupply: number;
  totalBorrowed: number;
  utilization: number;
  totalAPY: number;
  apr: number;
  grassHouseTotalApy: string;
};

// mock api calls
export function usePools(type: PoolType) {
  const [pools, setPools] = useState<PoolResponse[]>(null);
  const revalidatePools = () => {
    setTimeout(
      () =>
        setPools([
          {
            id: 0,
            symbol: "BNB",
            farmURL: "/#farm",
            totalSupply: 1_540_000_000,
            totalBorrowed: 1_180_000_000,
            utilization: 2.6,
            totalAPY: 3.76,
            apr: 1.26,
            grassHouseTotalApy: '1.00%',
          },
          {
            id: 1,
            symbol: "BNB",
            farmURL: "/#farm",
            totalSupply: 1_540_000_000,
            totalBorrowed: 1_180_000_000,
            utilization: 2.6,
            totalAPY: 3.76,
            apr: 1.26,
            grassHouseTotalApy: '1.00%',
          },
          {
            id: 2,
            symbol: "BNB",
            farmURL: "/#farm",
            totalSupply: 1_540_000_000,
            totalBorrowed: 1_180_000_000,
            utilization: 2.6,
            totalAPY: 3.76,
            apr: 1.26,
            grassHouseTotalApy: '1.00%',
          },
          {
            id: 3,
            symbol: "BNB",
            farmURL: "/#farm",
            totalSupply: 1_540_000_000,
            totalBorrowed: 1_180_000_000,
            utilization: 2.6,
            totalAPY: 3.76,
            apr: 1.26,
            grassHouseTotalApy: '1.00%',
          },
          {
            id: 4,
            symbol: "BNB",
            farmURL: "/#farm",
            totalSupply: 1_540_000_000,
            totalBorrowed: 1_180_000_000,
            utilization: 2.6,
            totalAPY: 3.76,
            apr: 1.26,
            grassHouseTotalApy: '1.00%',
          },
          {
            id: 5,
            symbol: "BNB",
            farmURL: "/#farm",
            totalSupply: 1_540_000_000,
            totalBorrowed: 1_180_000_000,
            utilization: 2.6,
            totalAPY: 3.76,
            apr: 1.26,
            grassHouseTotalApy: '1.00%',
          },
          {
            id: 6,
            symbol: "BNB",
            farmURL: "/#farm",
            totalSupply: 1_540_000_000,
            totalBorrowed: 1_180_000_000,
            utilization: 2.6,
            totalAPY: 3.76,
            apr: 1.26,
            grassHouseTotalApy: '1.00%',
          },
          {
            id: 7,
            symbol: "BNB",
            farmURL: "/#farm",
            totalSupply: 1_540_000_000,
            totalBorrowed: 1_180_000_000,
            utilization: 2.6,
            totalAPY: 3.76,
            apr: 1.26,
            grassHouseTotalApy: '1.00%',
          },
          {
            id: 8,
            symbol: "BNB",
            farmURL: "/#farm",
            totalSupply: 1_540_000_000,
            totalBorrowed: 1_180_000_000,
            utilization: 2.6,
            totalAPY: 3.76,
            apr: 1.26,
            grassHouseTotalApy: '1.00%',
          },
        ]),
      3000
    );
  };

  useEffect(() => {
    setPools(null);
    revalidatePools();
  }, [type]);

  return { data: pools };
}

export function useTVL() {
  const [tvl, setTVL] = useState<number>();
  useEffect(() => {
    setTimeout(() => {
      setTVL(10_000_000_000);
    }, 3000);
  }, []);
  return { data: tvl };
}
