import React, { useRef, useState } from "react";
import Slider from "react-slick";
import cx from "classnames";
import chevronRightDarkIcon from "~/images/icons/chevron-right-dark.svg";
import trustology from "~/images/testimonials/Trustology.svg";
import eureka from "~/images/testimonials/EurekaTrading.svg";
import onchain from "~/images/testimonials/OnchainCustodian.svg";
import re7 from "~/images/testimonials/RE7Capital.svg";
import TestimonialCard from "./TestimonialCard";

const Arrow = ({ className, direction, onClick }) => (
  <button
    className={cx(
      className,
      "w-7 h-7 rounded-full bg-gray-75 bg-opacity-50 flex items-center justify-center"
    )}
    onClick={onClick}
    type="button"
  >
    <img
      src={chevronRightDarkIcon}
      alt="chevron"
      className={cx(
        "absolute z-10 top-1/2 transform -translate-y-1/2 w-3",
        direction === "left" ? "left-2 rotate-180" : "right-2"
      )}
    />
  </button>
);

const Testimonials: React.FC = () => {
  const ref = useRef<any>();
  const next = () => {
    if (ref.current) ref.current.slickNext();
  };
  const previous = () => {
    if (ref.current) ref.current.slickPrev();
  };

  const [currentSlide, setCurrentSlide] = useState(0);

  const items = [
    {
      key: "trustology",
      icon: trustology,
      iconBG: "",
      content:
        "By combining Alpaca Finance's DeFi lending platform with Trustology's institutional-grade, FCA registered, insured and DeFi-friendly crypto custody solutions, we pave the way for institutional leveraged yield-farming to be safer, faster and easier.",
      author: "- Alex Batlin",
      position: "(Founder and CEO at Trustology)",
    },
    {
      key: "eureka",
      icon: eureka,
      iconBG: "bg-dark-450 border-white border-2",
      content:
        "Alpaca Finance is a magical tool for your Defi journey. With extremely easy-to-grasp usability and composability, Alpaca's versatility makes it a must-try whether you're a beginner or advanced crypto investor.",
      author: "- Kuan Sun",
      position: "(Founder/CEO at Eureka.Trading)",
    },
    {
      key: "onchain",
      icon: onchain,
      iconBG: "bg-dark-450 border-white border-2",
      content:
        "Alpaca Finance offers absolute security, higher yield and friendly user experience. It defines the new paradigm of leveraged yield farming, and is a trustworthy platform for any individual or institution entering Defi.",
      author: "- Jimmy Cheung",
      position: "(VP at Onchain Custodian)",
    },
    {
      key: "re7",
      icon: re7,
      iconBG: "",
      content:
        "Alpaca provides many types of earning opportunities in all market conditions. Whether bull or bear, the variety of assets and strategies at your disposal really makes the protocol an attractive destination for the yield seeking institution.",
      author: "- Evgeny Gokhberg",
      position: "(Managing Partner at RE7 Capital)",
    },
  ];

  return (
    <section className="relative ">
      <div className="relative container mx-auto mt-8 lg:mt-16">
        <div className="relative px-4 lg:px-0 inline-block text-2xl font-medium lg:text-section-header-desktop">
          <div className="absolute header-background z-0 top-0 left-0 h-full w-full"></div>
          <span className="relative z-0">Testimonials</span>
        </div>
      </div>
      <div className="relative lg:hidden">
        <Slider
          ref={ref}
          arrows={false}
          variableWidth
          centerMode
          adaptiveHeight
          beforeChange={(_, nextSlide) => setCurrentSlide(nextSlide)}
        >
          {items.map(({ key, ...props }, i) => (
            <div
              className="container mx-auto px-1"
              style={{ width: 230 }}
              key={key}
            >
              <TestimonialCard
                className={currentSlide !== i && "scale-90"}
                {...props}
              />
            </div>
          ))}
        </Slider>
        <div className="absolute inset-0 pointer-events-none lg:hidden">
          <div className="w-72 mx-auto relative h-full">
            <Arrow
              className="absolute top-1/2 transform -translate-y-1/2 left-0 w-5 pointer-events-auto"
              direction="left"
              onClick={previous}
            />
            <Arrow
              className="absolute top-1/2 transform -translate-y-1/2  right-0 w-5 pointer-events-auto"
              direction="right"
              onClick={next}
            />
          </div>
        </div>
      </div>
      <div className="hidden lg:container lg:mx-auto lg:grid lg:grid-cols-4 lg:gap-x-6">
        {items.map(({ key, ...props }) => (
          <TestimonialCard
            appearance="desktop"
            key={key}
            className="col-span-1"
            {...props}
          />
        ))}
      </div>
    </section>
  );
};

export default Testimonials;
