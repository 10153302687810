import { graphql } from "gatsby";
import React from "react";
import ProtocolSecurity from "~/index-page/ProtocolSecurity";
import InstitutionsTop from "~/institutions-page/InstitutionsTop";
import OurTrackRecord from "~/institutions-page/OurTrackRecord";
import Pools from "~/institutions-page/Pools";
import ProductOfferings from "~/institutions-page/ProductOfferings";
import LandingLayout from "~/landing/LandingLayout";
import Partners from "~/institutions-page/Partners";
import Testimonials, { Caurosel } from "~/index-page/Testimonials";
import Medias from "~/index-page/Medias";
import { PagesType } from "~/constants";

const InstitutionsPage: React.FC = () => (
  <LandingLayout pageType={PagesType.INSTITUTIONS}>
    <InstitutionsTop />
    <ProductOfferings />
    <Pools />
    {/* <OurTrackRecord /> */}
    <Testimonials />
    <Medias />
    <Partners />
    <ProtocolSecurity />
  </LandingLayout>
);

export default InstitutionsPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["common", "index", "institutions"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
