import React, { useState } from "react";
import cx from "classnames";
import { Trans, useI18next } from "gatsby-plugin-react-i18next";
import { CircleLoader } from "react-spinners";
import { PoolType, usePools, useTVL } from "./hooks";
import { formatCurrency } from "~/format/utils";
import texturedCircle from "~/images/graphics/textured-circle.svg";
import PoolCard from "./PoolCard";
import { usePrepareData } from "~/hooks/prepare/usePrepareData";
import { numberWithCommas } from "~/utils/number";
import { ILendingPool } from "~/entity";

const reOrderLendingPools = (lendingPools: ILendingPool[]): ILendingPool[] => {
  const sortedByList = ["bnb", "busd", "usdt", "btcb", "eth", "usdc", "tusd", "alpaca"];
  const sortedlendingPools = sortedByList.map((symbol) =>
    lendingPools.find(
      (lendingPool) => lendingPool.symbol.toLowerCase() === symbol
    )
  );
  return sortedlendingPools;
};

const Pools: React.FC = () => {
  const { language, t } = useI18next();
  const [type, setType] = useState<PoolType>("lending");
  const { summary } = usePrepareData();
  const { data: pools } = usePools(type);

  return (
    <section className="mt-28 relative">
      <img
        className="hidden lg:block absolute z-0 bottom-0 right-0"
        aria-hidden
        src={texturedCircle}
        width={500}
        alt=""
      />
      <div className="container mx-auto relative">
        <p className="text-base text-center mb-16 lg:text-2xl">
          <Trans ns="institutions" i18nKey="poolsDescription">
            <strong className="text-primary">Alpaca Finance</strong> provides
            multiple opportunities for you to earn{" "}
            <br className="hidden lg:block" /> safe & stable yields through our
            various lending & market neutral pools
          </Trans>
        </p>
        <div className="flex items-stretch border-b border-white mb-10 lg:px-10 lg:mb-0 lg:border-0">
          <button
            type="button"
            className="pl-px pr-4 -mb-px lg:-mb-0.5"
            onClick={() => setType("lending")}
          >
            <span
              className={cx(
                "inline-block text-base py-3 transition-colors duration-200 lg:text-2xl lg:pb-6",
                type === "lending"
                  ? "text-primary font-bold border-b-2 border-primary lg:border-b-4"
                  : "font-thin"
              )}
            >
              {t("Lending Pools")}
            </span>
          </button>
          {/* <div className="py-2 lg:pb-6">
            <div className="h-full pl-px bg-white" />
          </div> */}
          {/* <button
            type="button"
            className="pl-4 -mb-px lg:-mb-0.5"
            onClick={() => setType("neutral")}
          >
            <span
              className={cx(
                "inline-block text-base py-3 transition-colors duration-200 lg:text-2xl lg:pb-6",
                type === "neutral"
                  ? "text-primary font-bold border-b-2 border-primary lg:border-b-4"
                  : "font-thin"
              )}
            >
              {t("Market Neutral Pools")}
            </span>
          </button> */}
        </div>
        <div className="lg:border lg:border-white lg:rounded-2xl lg:px-4 lg:pb-14">
          <div className="border border-white rounded-lg text-center py-6 text-base leading-relaxed lg:border-0 lg:text-3xl lg:py-16 lg:border-b lg:rounded-none">
            Total Value Locked: <br className="lg:hidden" />{" "}
            <strong className="bg-clip-text bg-gradient-to-br from-primary to-green-50 text-transparent">
              {summary === null
                ? "-"
                : `$${numberWithCommas(
                    Math.floor(parseFloat(summary.overall.tvl))
                  )}`}
            </strong>
          </div>
          {!summary && (
            <div className="flex items-center justify-center p-7">
              <CircleLoader color="#2DEA8F" size={50} />
            </div>
          )}
          {/* lending pools */}
          {summary && type === "lending" && (
            <div className="mt-3 lg:px-6">
              <div className="hidden lg:grid lg:grid-cols-12 lg:gap-x-2 lg:text-xl lg:my-7">
                <div className="col-span-2 pl-12">{t("Asset")}</div>
                <div className="col-span-2 text-right">{t("Total Supply")}</div>
                <div className="col-span-2 text-right">
                  {t("Total Borrowed")}
                </div>
                <div className="col-span-2 text-right pr-3">
                  {t("Utilization")}
                </div>
                <div className="col-span-2 text-right pr-9">
                  {t("Total APY")}
                </div>
              </div>
              {reOrderLendingPools(summary.lendingPools).map(
                (lendingPool, index) => (
                  <PoolCard
                    className="mb-3 last:mb-0"
                    key={lendingPool.sourceName}
                    id={index}
                    symbol={lendingPool.symbol}
                    farmURL={`https://app.alpacafinance.org/lend`}
                    totalSupply={parseFloat(lendingPool.totalSupply)}
                    totalBorrowed={parseFloat(lendingPool.totalBorrowed)}
                    utilization={
                      (parseFloat(lendingPool.totalBorrowed) /
                        parseFloat(lendingPool.totalSupply)) *
                      100
                    }
                    apr={parseFloat(
                      lendingPool.stakingAprs.find(
                        (pool) => pool.stakingContract === "Alpaca FairLaunch"
                      ).apr
                    )}
                    totalAPY={parseFloat(lendingPool.totalApy)}
                    grassHouseTotalApy = {summary.overall.grasshouseTotalApy}
                  />
                )
              )}
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default Pools;
