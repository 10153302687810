import React from "react";
import quotation from "~/images/testimonials/quotation.svg";
import cx from "classnames";

interface Props {
  icon: string;
  iconBG: string;
  content: string;
  author: string;
  position: string;
}

const TestimonialCard: React.FC<Props> = ({
  icon,
  iconBG,
  content,
  author,
  position,
}) => (
  <div
    className={cx(
      "relative block p-4 border border-gray-100 bg-testimonial-gradient rounded-xl lg:mt-28 mt-16 transform-gpu transition-transform duration-300"
    )}
  >
    <div
      className={`rounded-full absolute z-20 lg:ml-32 ml-24 lg:-top-14 -top-12 lg:h-28 lg:w-28 h-24 w-24 flex justify-center items-center ${iconBG}`}
    >
      <img className="" src={icon} />
    </div>
    <div className="absolute z-20 ml-4 -top-8">
      <img src={quotation} />
    </div>
    <span className="lg:text-base text-xs lg:px-4 lg:w-64 inline-block lg:mt-28 mt-16 lg:h-72 h-48">
      {content}
    </span>
    <span className="inline-block lg:text-base text-xs lg:px-4 text-primary font-bold lg:mt-6 mt-4">
      {author}
    </span>
    <span className="inline-block lg:text-base lg:px-4 text-2xs">
      {position}
    </span>
  </div>
);

export default TestimonialCard;
