import millify from 'millify';

const locale = {
  en: 'en-US',
  th: 'th-TH',
};

export function formatCurrency(
  amount: number,
  language = 'en',
  currency = 'USD',
) {
  return new Intl.NumberFormat(locale[language], {
    style: 'currency',
    currency,
  }).format(amount);
}

export function humanFormatCurrency(
  amount: number,
  language = 'en',
  currency = 'USD',
) {
  const result = new Intl.NumberFormat(locale[language], {
    style: 'currency',
    currency,
  }).formatToParts(amount);
  const symbol =
    result.find(({ type }) => type === 'currency')?.value || currency;
  return `${symbol} ${millify(amount, { precision: 2 })}`;
}

export function formatPercentage(amount, language) {
  return `${new Intl.NumberFormat(locale[language]).format(amount)}%`;
}
