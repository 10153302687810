import React from "react";
import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next";
import Button from "~/ui/Button";
import particles from "~/images/graphics/particles.png";
import texturedCircle from "~/images/graphics/textured-circle.svg";

const InstitutionsTop: React.FC = () => {
  const { t } = useTranslation();
  return (
    <section
      className="pt-32 pb-40 bg-center bg-cover relative lg:pt-36 lg:py-80 overflow-hidden"
      style={{ backgroundImage: `url(${particles})` }}
    >
      <img
        className="absolute z-0 top-1/3 left-1/2 max-w-sm transform -translate-x-1/2 -translate-y-1/2 lg:max-w-none lg:opacity-60 lg:top-1/2"
        aria-hidden
        src={texturedCircle}
        alt=""
      />
      <div className="container mx-auto relative">
        <p className="text-center font-thin lg:text-2xl">
          <Trans ns="institutions" i18nKey="title">
            Are you an institution looking to
            <br className="lg:hidden" /> earn yields with DeFi?
          </Trans>
        </p>
        <p className="text-xl font-semibold leading-normal text-center mt-20 lg:text-5xl lg:font-normal lg:leading-tight">
          <Trans ns="institutions" i18nKey="description">
            Get in touch to
            <br /> understand how we can support <br />
            <span></span>
            <strong className="text-primary font-semibold">
              your requirements
            </strong>
          </Trans>
        </p>
        <div className="text-center mt-28 lg:mt-12">
          <Link to="https://forms.gle/HMJSZhu3LU2dymgs8" target="_blank" rel="nofollow noopener noreferrer">
            <Button
              appearance="primary"
              className="bg-primary bg-opacity-10 py-1 px-6 lg:px-4 lg:bg-opacity-5"
            >
              <span className="text-sm font-bold lg:text-xl lg:font-medium">
                {t("Contact Us")}
              </span>
            </Button>
          </Link>
        </div>
      </div>
    </section>
  );
};
export default InstitutionsTop;
